<template>
    <div
        :class="[
            'loader-image-transparent relative w-full cursor-pointer lg:bg-black',
            data.class,
        ]"
        @click="data.action"
    >
        <img
            :src="data.img + locale + '.jpg'"
            class="hidden w-full object-contain lg:block lg:h-full lg:cursor-pointer lg:object-cover lg:px-0 2xl:object-fill"
            :alt="data.title"
            loading="lazy"
            @click="data.action"
        />
        <img
            :src="data.img_mobile + locale + '.jpg'"
            class="w-full rounded-xl object-contain lg:hidden"
            :alt="data.title"
            loading="lazy"
            @click="data.action"
        />
        <div class="banner-info hidden lg:block">
            <div class="flex items-center">
                <div class="banner-info__title" v-html="data.title"></div>
                <img
                    v-if="data.imageTitle"
                    class="banner-info__img"
                    :src="data.imageTitle"
                    alt="sport"
                />
            </div>
            <div class="banner-info__sub-title" v-html="data.subTitle"></div>
            <img
                v-if="data.icon"
                class="banner-info__icon"
                :src="data.icon"
                alt="sport"
            />
        </div>

        <div class="banner-info-mb block lg:hidden">
            <div class="flex items-center">
                <div
                    class="banner-info-mb__title"
                    v-html="data.titleMobile"
                ></div>
                <img
                    v-if="data.imageTitleMobile"
                    class="banner-info-mb__img"
                    :src="data.imageTitleMobile"
                    alt="sport"
                />
            </div>
            <div
                class="banner-info-mb__sub-title"
                v-html="data.subTitleMobile"
            ></div>
            <img
                v-if="data.icon"
                class="banner-info-mb__icon"
                :src="data.icon"
                alt="sport"
            />
        </div>
    </div>
</template>
<script setup>
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'

const { data } = defineProps(['data'])
const breakpoints = useBreakpoints(breakpointsTailwind)
const largerThanSm = breakpoints.greater('lg') // only larger than sm
const imgSrc = ref('')
const { isMobile } = useDevice()
const { locale } = useI18n({})
imgSrc.value = isMobile ? data.img_mobile : data.img

watch(
    () => largerThanSm.value,

    (newVal, _) => {
        imgSrc.value = newVal ? data.img : data.img_mobile
    }
)
</script>
<style scoped lang="scss">
.banner-default {
    display: flex;
    justify-content: center;
    font-family: 'inter';
    .banner-info {
        position: absolute;
        color: white;
        width: 100%;
        max-width: 1512px;
        padding-top: min(6.82vw, 130px);
        padding-left: min(7.8125vw, 150px);
        &__title {
            font-weight: 600;
            font-style: normal;
            font-weight: 600;
            font-size: min(2.083vw, 40px);
            line-height: min(3.177vw, 61px);
            background: linear-gradient(360deg, #fff2ae 17.5%, #f7d936 79.17%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            :deep() {
                span {
                    font-weight: 600;
                    line-height: min(3.1515vw, 60.51px);
                    font-size: min(2.604vw, 50px);
                }
            }
        }
        &__sub-title {
            margin-top: min(0.521vw, 10px);
            font-size: min(1.0416vw, 20px);
            line-height: min(1.2604vw, 24.2px);
        }
        &__icon {
            margin-top: min(30px, 1.563vw);
            height: min(52px, 2.71vw);
        }
    }
    .banner-info-mb {
        position: absolute;
        color: white;
        top: max(16px, 6.451vw);
        left: max(20px, 8.06451vw);
        &__title {
            font-weight: 700;
            font-size: max(16px, 3.7209vw);
            line-height: max(4.503vw, 19.36px);
        }
        &__sub-title {
            margin-top: max(2.32558vw, 10px);
            font-size: max(2.79vw, 12px);
            line-height: max(3.3767vw, 14.52px);
        }
    }

    &-sport {
        .banner-info {
            padding-top: min(3.856vw, 74px);
            &__title {
                line-height: min(3.784vw, 72.61px);
                font-size: min(60px, 3.125vw);
            }
            &__img {
                height: min(3.49vw, 67px);
                margin-left: min(1.615vw, 31px);
            }
            &__sub-title {
                font-weight: 300;
                font-size: min(1.563vw, 30px);
                line-height: min(1.891vw, 36.31px);
            }
        }
        .banner-info-mb {
            top: 6.047vw;
            left: 4.651vw;
            &__title {
                font-size: 4.651vw;
                line-height: 5.581vw;
                background: linear-gradient(
                    360deg,
                    #fff2ae 17.5%,
                    #f7d936 79.17%
                );
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
            }
            &__icon {
                margin-top: 4.651vw;
                height: 5.581vw;
            }
            &__sub-title {
                font-weight: 400;
            }
            &__img {
                height: 6.977vw;
                margin-left: 0.93vw;
            }
        }
    }
    &-two {
        .banner-info {
            &__title {
                line-height: min(3.467vw, 66.56px);
                font-size: min(55px, 2.865vw);
            }
            &__sub-title {
                max-width: min(351px, 18.281vw);
                line-height: min(2.206vw, 42.36px);
                font-size: min(35px, 1.823vw);
            }
        }
        .banner-info-mb {
            &__title {
                line-height: 5.581vw;
                font-size: 4.651vw;
            }
            &__sub-title {
                max-width: 28.837vw;
                line-height: 3.721vw;
                font-size: 2.791vw;
            }
        }
    }
    &-three {
        .banner-info {
            &__title {
                line-height: min(4.097vw, 78.66px);
                font-size: min(3.385vw, 65px);
            }
        }
    }
}
</style>
